import { css as emotionCss } from '@emotion/css';

export const baseFormStyle = (prefixCls: string) => {
  return emotionCss`
  > .${prefixCls}-form-item {
    min-height: 34px;
    margin-bottom: 24px;
  
    > .${prefixCls}-form-item-label {
      user-select: none;
      color: rgb(0 0 0 / 85%);
    }
  
    > .${prefixCls}-form-item-control {
      color: rgb(0 0 0 / 65%);
  
      > .${prefixCls}-form-item-explain {
        user-select: none;
      }
    }
  }
  `;
};
export const baseFormInlineStyle = (prefixCls: string) => {
  return emotionCss`
  > .${prefixCls}-form-item {
    margin-bottom: 24px;
  
    > .${prefixCls}-form-item-label {
      min-width: 60px;
    }
  }
    `;
};

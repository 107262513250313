import { Empty } from 'antd';

/**
 * @name 自定义无数据组件
 */
const CustomEmpty: React.FC = () => {
  return (
    <div>
      <Empty description={<span>暂无信息</span>} />
    </div>
  );
};

export default CustomEmpty;

import * as XLSX from 'xlsx';

const renderColumnValue = (
  column: Record<string, any> = {},
  item: Record<string, any> = {},
  index: number,
) => {
  const { dataIndex, valueEnum, render, renderInExport } = column;
  const recordValue = item[dataIndex];
  let result = '';
  /**
   * @name 优先级
   */
  if (renderInExport) {
    result = renderInExport(recordValue, item, index);
  } else if (render) {
    const renderResult = render(
      recordValue === '-' ? undefined : recordValue,
      item,
      index,
    );
    if (Array.isArray(renderResult)) {
      // 多个遍历组件dom
      result = renderResult?.map((v) => v?.props?.children || v).join(' ');
    } else if (typeof renderResult === 'object') {
      // 组件dom
      result = renderResult?.props?.children;
    } else {
      result = renderResult;
    }
  } else if (valueEnum) {
    result = valueEnum[recordValue]?.text;
  } else {
    result = recordValue;
  }
  return result;
};
/**
 * @name 导出表格数据
 */
export const exportTableData = ({
  data,
  exportName,
  exportFileFormat,
  columns: _columns,
}: {
  data: any[];
  exportName: string;
  exportFileFormat: string;
  columns: any[];
}) => {
  return new Promise<void>((resolve, reject) => {
    if (!data?.length) {
      reject('没有导出数据');
    }
    try {
      const columns = _columns.filter((fil) => !fil.hideInExport);
      const header = columns.map((v) => v.title);
      const content = data.map((item: Record<string, any>, index) => {
        return columns.map((column) => {
          return renderColumnValue(column, item, index);
        });
      });
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.aoa_to_sheet([header, ...content]);
      XLSX.utils.book_append_sheet(workbook, worksheet, 'sheet1');
      const exportFileName = `${exportName}.${exportFileFormat}`;
      XLSX.writeFile(workbook, exportFileName);
      resolve();
    } catch (err) {
      reject(err);
    }
  });
};

// import { LogoBlackText } from '@/assets/icons';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

/**
 * @name 自定义加载组件
 */
const CustomLoading: React.FC = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 10,
        }}
      >
        <Spin indicator={<LoadingOutlined style={{ fontSize: 18 }} spin />} />
        {/* <LogoBlackText style={{ width: 80 }} /> */}
      </div>
    </div>
  );
};

export default CustomLoading;

import { ConfigProvider, Form, Space, Spin } from 'antd';
import { useContext } from 'react';
import { BaseFormProps } from '../../types';
import { formatFormItem } from '../../utils';
import { baseFormInlineStyle, baseFormStyle } from './index.style';

/**
 * 📦 内置组件 - 隐力科技
 * @name 基础表单
 * @package @/aismile-atom
 * @author xuguanqun
 */
const BaseForm: React.FC<BaseFormProps> = ({
  form,
  data,
  formItemList,
  formLoading = false,
  formProps,
  readonly,
  className,
  style,
}) => {
  /**
   * @name CONTEXT
   */
  const ANTD_CONTEXT = useContext(ConfigProvider.ConfigContext);
  const prefixCls = ANTD_CONTEXT.getPrefixCls();
  const { layout = 'horizontal', labelCol, wrapperCol } = formProps || {};
  return (
    <>
      <Spin spinning={formLoading}>
        <Form
          className={[
            baseFormStyle(prefixCls),
            layout === 'inline' && baseFormInlineStyle(prefixCls),
            className,
          ]
            .filter(Boolean)
            .join(' ')}
          form={form}
          layout={layout}
          labelCol={layout !== 'inline' ? labelCol || { span: 5 } : undefined}
          wrapperCol={
            layout !== 'inline' ? wrapperCol || { span: 16 } : undefined
          }
          style={style}
        >
          {formItemList?.filter(Boolean).map((item: any, index) => {
            const {
              colon,
              label,
              name,
              tooltip,
              required,
              rules,
              extra,
              valueDom,
              ...formItemProps
            } = formatFormItem(item, { data, readonly });
            return extra ? (
              <Form.Item
                key={name || index}
                colon={colon}
                label={label}
                tooltip={tooltip}
                required={required}
              >
                <Space size={layout === 'inline' ? 0 : 'middle'} align="start">
                  <Form.Item
                    name={name}
                    rules={
                      rules ||
                      (required
                        ? [{ required: true, message: `请输入${label}` }]
                        : [])
                    }
                    {...formItemProps}
                    style={{ marginBottom: 0 }}
                  >
                    {valueDom}
                  </Form.Item>
                  <Form.Item style={{ marginBottom: 0 }}>{extra}</Form.Item>
                </Space>
              </Form.Item>
            ) : (
              <Form.Item
                key={name || index}
                colon={colon}
                label={label}
                name={name}
                tooltip={tooltip}
                required={required}
                rules={
                  rules ||
                  (required
                    ? [{ required: true, message: `请输入${label}` }]
                    : [])
                }
                {...formItemProps}
              >
                {valueDom}
              </Form.Item>
            );
          })}
        </Form>
      </Spin>
    </>
  );
};
export default BaseForm;

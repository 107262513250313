import { ConfigProvider, Typography } from 'antd';
import { useContext, useState } from 'react';
import { ParagraphProps } from '../../types';
import { paragraphStyle } from './index.style';

/**
 * 📦 内置组件 - 隐力科技
 * @name 支持展开收起的Paragraph
 * @package @/aismile-atom
 * @author xuguanqun
 */
const Paragraph: React.FC<ParagraphProps> = ({
  children,
  copyable,
  ellipsis,
  ellipsisRows = 2,
  expandable,
  ellipsisSuffix,
  style,
}) => {
  const [expand, setexpand] = useState<boolean>(false);
  /**
   * @name CONTEXT
   */
  const ANTD_CONTEXT = useContext(ConfigProvider.ConfigContext);
  const prefixCls = ANTD_CONTEXT.getPrefixCls();
  const TEXT = children + '';
  return (
    <Typography.Paragraph
      className={paragraphStyle(ellipsisRows)}
      title={TEXT}
      copyable={copyable ? { text: TEXT } : false}
      ellipsis={
        (ellipsis || expandable) && !expand
          ? {
              rows: ellipsisRows,
              expandable,
              symbol: (
                <span
                  onClick={(e) => {
                    setexpand(true);
                    e.stopPropagation();
                  }}
                >
                  展开
                </span>
              ),
              suffix: ellipsisSuffix,
            }
          : false
      }
      style={style}
    >
      {TEXT}
      {expand ? (
        <span
          className={`${prefixCls}-typography-expand`}
          onClick={() => setexpand(false)}
          style={{ marginLeft: 5 }}
        >
          收起
        </span>
      ) : null}
    </Typography.Paragraph>
  );
};

export default Paragraph;

import { css as emotionCss } from '@emotion/css';

const scrollbarStyle = (colorPrimaryBorderHover: string) => `
  &::-webkit-scrollbar {
    height: 11px;
    width: 12px;
  }
  &::-webkit-scrollbar-track {
    background-color: #fff;
  }
  &::-webkit-scrollbar-thumb:horizontal {
    background: linear-gradient(to right, #fff, ${colorPrimaryBorderHover}, #fff);
  }
  &::-webkit-scrollbar-thumb:vertical {
    background: linear-gradient(to bottom, #fff, ${colorPrimaryBorderHover}, #fff);
  }
`;
export const tableStyle = ({
  prefixCls,
  showToolBar,
  colorPrimary,
  colorPrimaryBorderHover,
}: any) => {
  return emotionCss`
  > .${prefixCls}-pro-table-search {
    margin-bottom: 10px!important;
    margin-block-end: 10px;
    > .${prefixCls}-pro-query-filter {
      padding: 10px 20px!important;
    }
  }
  
  > .${prefixCls}-pro-card {
    > .${prefixCls}-pro-card-body {
      padding: 0;
  
      > .${prefixCls}-pro-table-list-toolbar {
        ${!showToolBar ? 'display: none;' : ''}
        padding: 5px 10px 5px 0;
        // 工具栏
        user-select: none;
  
        .${prefixCls}-pro-table-list-toolbar-container {
          padding: 0;
          height: 40px;
  
          > .${prefixCls}-pro-table-list-toolbar-left {
            width: auto;
            overflow: unset;
            .${prefixCls}-pro-table-list-toolbar-title {
              height: 100%;
              font-size: 15px;
  
              > .${prefixCls}-custom-title {
                padding-left: 10px;
                border-left: 4px solid ${colorPrimary};
  
                > .${prefixCls}-custom-subtitle {
                  margin-left: 8px;
                  color: rgb(0 0 0 / 45%);
                  font-size: 14px;
                  font-weight: normal;
                }
              }
  
              .${prefixCls}-switch-group {
                display: flex;
                flex-wrap: wrap;
                margin-left: 10px;
                gap: 10px;
  
                > .${prefixCls}-switch-item {
                  position: relative;
  
                  > .${prefixCls}-switch-title {
                    font-weight: 400;
                    font-size: 12px;
                    position: absolute;
                    top: -13px;
                    left: 3px;
                  }
  
                  .${prefixCls}-radio-button-wrapper {
                    height: 27px;
                    font-size: 12px;
                    line-height: 25px;
                  }
                }
              }
  
              .${prefixCls}-middle-area {
                margin-left: 10px;
                font-weight: 400;
              }
            }
          }
        }
      }
  
      > .${prefixCls}-table-wrapper {
        // 表格
        .${prefixCls}-table {
          .${prefixCls}-table-container {
            .${prefixCls}-table-header {
              user-select: none;
              color: rgb(0 0 0 / 85%);

              .${prefixCls}-table-thead > tr > th {
                font-weight: 500;
                color: rgb(0 0 0 / 65%);
              }

            }
  
            .${prefixCls}-table-body {
              color: rgb(0 0 0 / 65%);
              overflow-y: auto !important;
              ${scrollbarStyle(colorPrimaryBorderHover)}

              .serial-number-column{
                font-weight: 600;
              }
  
              .${prefixCls}-typography {
                color: rgb(0 0 0 / 65%);
              }
            }
            .${prefixCls}-table-sticky-scroll {
              .${prefixCls}-table-sticky-scroll-bar {
                background: linear-gradient(to right, #fff, ${colorPrimaryBorderHover}, #fff);
                opacity: 1;
              }
            }
          }
          // 表格尺寸 默认
          .${prefixCls}-table-row {
            &:hover {
              > .${prefixCls}-table-cell > .${prefixCls}-pro-field-index-column {
                background-color: #fff;
              }
            }
  
            .${prefixCls}-table-cell {
              padding: 13px;
            }
            // 序号栏
            td.${prefixCls}-number-column {
              padding: 0;
  
              > .${prefixCls}-pro-field-index-column {
                color: #8c8c8c;
                font-weight: 500;
                background-color: #fafafa;
              }
            }
          }
        }
        // 中等
        .${prefixCls}-table-middle {
          .${prefixCls}-table-cell {
            padding: 8px !important;
          }
        }
        // 紧凑
        .${prefixCls}-table-small {
          .${prefixCls}-table-cell {
            padding: 3px !important;
          }
        }
        // 分页器
        .${prefixCls}-pagination {
          user-select: none;
          margin: 0;
          padding: 15px;
          border: 1px solid #f0f0f0;
        }
      }
    }
  }
  
  `;
};

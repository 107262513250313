import { css as emotionCss } from '@emotion/css';

export const drawerFormStyle = (prefixCls: string) => {
  return emotionCss`
  > .${prefixCls}-drawer-wrapper-body {
    > .${prefixCls}-drawer-header {
      user-select: none;
    }
    > .${prefixCls}-drawer-body {
      padding: 15px;
    }
  }
  `;
};

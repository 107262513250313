import { css as emotionCss } from '@emotion/css';

export const paragraphStyle = (ellipsisRows: number) => {
  return emotionCss`
  margin-bottom: 0 !important;
  ${
    ellipsisRows === 1
      ? `
  display: -webkit-inline-box;
  white-space: pre-wrap!important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  `
      : ''
  }
  `;
};

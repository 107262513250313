import React from 'react';
import { ConfigProviderProps } from '../types';

/**
 * @default
 */
const permissionBtnApi = (id: string) =>
  `auth/v1/user/permission_none/permission/btn/${id}`;
const defaultParams = {
  api: {
    permissionBtnApi,
  },
};
let CONTEXT = React.createContext(defaultParams);
/**
 * @name ConfigProvider
 */
export const ConfigProvider: ConfigProviderProps = {
  config: (params) => {
    const { api = {}, ...props } = params || {};
    if (!api?.permissionBtnApi) {
      api.permissionBtnApi = permissionBtnApi;
    }
    const _CONTEXT: any = React.createContext({
      ...defaultParams,
      api,
      ...props,
    });
    CONTEXT = _CONTEXT;
  },
};

export { CONTEXT };

import { OrderedListOutlined } from '@ant-design/icons';
import Field from '@ant-design/pro-field';
import { Input } from 'antd';
import { Paragraph } from '../components';
import { TableCURDColumnsProps } from '../types';
import { apiProps } from '../types/table-curd';

/**
 * 序号栏
 */
const NUM_COL = {
  className: 'serial-number-column',
  title: <OrderedListOutlined />,
  width: 50,
  fixed: 'left',
  align: 'center',
  hideInExport: true,
  search: false,
  render: (v: any, record: any, index: string) => (
    <div className="serial-number-column">{index + 1}</div>
  ),
};
/**
 * @name 重写render
 */
const rewriteRender = (args: any[] = [], column: Record<string, any> = {}) => {
  const [text, row, index, action, schema] = args;
  const {
    render,
    copyable,
    ellipsis,
    ellipsisRows,
    ellipsisSuffix,
    expandable,
  } = column;
  let result = '';
  if (render) {
    /**
     * @name 有render时也支持copyable、ellipsis、expandable属性
     * @description 仅支持render出的dom中的内容为字符串、数字等，高阶组件大概率无法展示
     **/
    const renderResult = render(
      text === '-' ? undefined : text,
      row,
      index,
      action,
      schema,
    );
    if (Array.isArray(renderResult)) {
      // 多个遍历组件dom
      result = renderResult?.map((v) => v?.props?.children || v).join(' ');
    } else if (typeof renderResult === 'object') {
      // 组件dom
      result = renderResult?.props?.children;
    } else {
      result = renderResult;
    }
  } else {
    result = text;
  }
  return (
    <Paragraph
      {...{
        copyable,
        ellipsis,
        ellipsisRows,
        ellipsisSuffix,
        expandable,
      }}
    >
      {result || '-'}
    </Paragraph>
  );
};
/**
 * @name Table转换表头
 */
export const formatColumns = (
  columns: TableCURDColumnsProps[] = [],
  serialNumber: boolean,
) => {
  /** @name 转换order */
  const orderDict: any = {};
  const hasOrder = columns.findIndex((fil) => fil.order) > -1;
  if (hasOrder) {
    const rows4order = columns
      .filter((fil) => fil && typeof fil.order !== 'undefined')
      .sort((a: any, b: any) => a.order - b.order); // 正序
    const orderNumberList = rows4order
      .map((v) => v.order)
      .sort((a: any, b: any) => b - a); // 倒序
    rows4order.forEach((f: any, index) => {
      orderDict[f.title || f.dataIndex] = orderNumberList[index];
    });
  }
  /** @name 处理表头 */
  const afterColumns = columns.map((column) => {
    const { copyable, ellipsis, expandable, ...col } = column;
    const resCol: Record<string, any> = { ...col };
    if (copyable || ellipsis || expandable) {
      // 对有复制、省略、展开功能的列 进行render重写
      resCol.render = (...args: any) => {
        return rewriteRender([...args], column);
      };
    }
    return resCol;
  });
  return [serialNumber && NUM_COL, ...afterColumns].filter(Boolean);
};
/**
 * @name TableCURD转换表头
 */
export const formatColumnsCURD = (
  columns: TableCURDColumnsProps[] = [],
  serialNumber: boolean,
) => {
  /** @name 转换order */
  const orderDict: any = {};
  const hasOrder = columns.findIndex((fil) => fil.order) > -1;
  if (hasOrder) {
    const rows4order = columns
      .filter((fil) => fil && typeof fil.order !== 'undefined')
      .sort((a: any, b: any) => a.order - b.order); // 正序
    const orderNumberList = rows4order
      .map((v) => v.order)
      .sort((a: any, b: any) => b - a); // 倒序
    rows4order.forEach(
      (f: any, index) =>
        (orderDict[f.title || f.dataIndex] = orderNumberList[index]),
    );
  }
  /** @name 处理表头 */
  const afterColumns = columns.map((column) => {
    const {
      order,
      searchKey,
      searchTitle,
      copyable,
      ellipsis,
      expandable,
      render,
      ...col
    } = column;
    const resCol: Record<string, any> = { ...col };
    if (copyable || ellipsis || expandable) {
      // 对有复制、省略、展开功能的列 进行render重写
      resCol.render = (...args: any) => {
        return rewriteRender([...args], column);
      };
    } else {
      resCol.render = render;
    }
    // 排序
    if (order) {
      resCol.order = orderDict[resCol?.title || resCol?.dataIndex];
    }
    // search
    if (!resCol?.search) {
      resCol.search = false;
    }
    if (searchKey || searchTitle) {
      resCol.formItemProps = {
        label: searchTitle || resCol?.title,
        name: searchKey || resCol?.dataIndex,
      };
    }
    return resCol;
  });
  return [serialNumber && NUM_COL, ...afterColumns].filter(Boolean);
};
/**
 * @name 解析权限按钮
 */
export const formatOpsBtn = (data: any[]) => {
  return data?.map(({ url = '', ...props }: any) => {
    let key = '';
    let queryParams: Record<string, any> = {};
    if (url.indexOf('?') > -1) {
      const [pathName, paramsUrl] = url.split('?');
      key = pathName.replace(/\//g, '');
      // 解析query参数
      const p: Record<string, any> = {};
      if (paramsUrl.indexOf('&') > -1) {
        paramsUrl.split('&').forEach((keyValue: string) => {
          if (keyValue.indexOf('=') > -1) {
            const [key, value] = keyValue?.split('=');
            p[key] = value;
          }
        });
      } else if (paramsUrl.indexOf('=') > -1) {
        const [key, value] = paramsUrl?.split('=');
        p[key] = value;
      }
      queryParams = p;
    } else {
      key = url.replace(/\//g, '');
    }
    return {
      url,
      key,
      ...props,
      queryParams,
    };
  });
};
/**
 * @name 准备按钮数据
 */
export const renderOpsData = (data: Record<string, any>[]) => {
  const _data = data?.filter(({ url }) => url !== '/query');
  const formatBtns = formatOpsBtn(_data);
  const tableHeader: any[] = [];
  const tableAction: any[] = [];
  const funcMenu: any[] = [];
  formatBtns?.forEach((item) => {
    const { type, queryParams, position } = item;
    const _position = position || queryParams?.pos || 'tableHeader';
    if (type === 'button' && _position === 'tableHeader') {
      tableHeader.push(item);
    } else if (type === 'button' && _position === 'tableAction') {
      tableAction.push(item);
    } else if (type === 'func_menu') {
      funcMenu.push(item);
    }
  });
  const tableActionDict: Record<string, any> = {};
  const tableHeaderDict: Record<string, any> = {};
  funcMenu.forEach(({ id }) => {
    tableActionDict[id] = tableAction.filter(({ parentId }) => parentId === id);
  });
  funcMenu.forEach(({ id }) => {
    tableHeaderDict[id] = tableHeader.filter(({ parentId }) => parentId === id);
  });
  return {
    tableHeader,
    tableAction,
    tableActionDict,
    funcMenu,
    tableHeaderDict,
  };
};
/**
 * @name 权限按钮
 */
export const opsBtnRequest = (request: any, api: any, ruleId?: string) => {
  let req: any = null;
  if (typeof api === 'function') {
    const url = api(ruleId);
    req = request(url, { method: 'POST' });
  } else if (typeof api === 'string') {
    const url: any = `${api}/${ruleId}`;
    req = request(url, { method: 'POST' });
  }
  return req;
};
/**
 * @name 详情
 */
export const detailRequest = (
  request: any,
  detail: apiProps['detail'],
  record: Record<string, any>,
) => {
  let req = null;
  if (typeof detail === 'function') {
    const detailback = detail(record);
    let url = '';
    let data = null;
    if (typeof detailback === 'string') {
      url = detailback;
    } else {
      url = detailback[0];
      data = detailback[1];
    }
    req = request(url, { method: 'POST', data });
  } else if (typeof detail === 'string') {
    req = request(detail, { method: 'POST' });
  }
  return req;
};
/**
 * @name 新增
 */
export const createRequest = (
  request: any,
  create: apiProps['create'],
  params: Record<string, any>,
) => {
  let req = null;
  if (typeof create === 'function') {
    const detailback = create(params);
    let url = '';
    let data = null;
    if (typeof detailback === 'string') {
      url = detailback;
    } else {
      url = detailback[0];
      data = detailback[1];
    }
    req = request(url, { method: 'POST', data });
  } else if (typeof create === 'string') {
    req = request(create, { method: 'POST' });
  }
  return req;
};
/**
 * @name 修改
 */
export const modifyRequest = (
  request: any,
  modify: apiProps['modify'],
  params: Record<string, any>,
  detailData: Record<string, any>,
) => {
  let req = null;
  if (typeof modify === 'function') {
    const detailback = modify(params, detailData);
    let url = '';
    let data = null;
    if (typeof detailback === 'string') {
      url = detailback;
    } else {
      url = detailback[0];
      data = detailback[1];
    }
    req = request(url, { method: 'POST', data });
  } else if (typeof modify === 'string') {
    req = request(modify, { method: 'POST' });
  }
  return req;
};
/**
 * @name 删除
 */
export const removeRequest = (
  request: any,
  remove: apiProps['remove'],
  record: Record<string, any>,
) => {
  let req = null;
  if (typeof remove === 'function') {
    const detailback = remove(record);
    let url = '';
    let data = null;
    if (typeof detailback === 'string') {
      url = detailback;
    } else {
      url = detailback[0];
      data = detailback[1];
    }
    req = request(url, { method: 'POST', data });
  } else if (typeof remove === 'string') {
    req = request(remove, { method: 'POST' });
  }
  return req;
};
/**
 * @name 批量删除
 */
export const batchRemoveRequest = (
  request: any,
  batchRemove: apiProps['batchRemove'],
  selectedRowKeys: any[],
  selectedRows: any[],
) => {
  let req = null;
  if (typeof batchRemove === 'function') {
    const detailback = batchRemove(selectedRowKeys, selectedRows);
    let url = '';
    let data = null;
    if (typeof detailback === 'string') {
      url = detailback;
    } else {
      url = detailback[0];
      data = detailback[1];
    }
    req = request(url, { method: 'POST', data });
  } else if (typeof batchRemove === 'string') {
    req = request(batchRemove, { method: 'POST' });
  }
  return req;
};
/**
 * @name 转换表单条目
 */
type formatFormItemProps = (
  item: Record<string, any>,
  componentProps: Record<string, any>,
) => Record<string, any>;

export const formatFormItem: formatFormItemProps = (
  item,
  { data, readonly: formReadOnly },
) => {
  const {
    labelRequired,
    required,
    label,
    name,
    readonly,
    rules,
    renderFormItem,
    placeholder,
    fieldProps = {},
    mode = 'edit',
    valueType,
    valueEnum,
    ...props
  } = item;
  // 是否只读
  const _readonly = formReadOnly || readonly;
  // 必填星号 if else 先后即代表权重
  let _required = false;
  if (typeof labelRequired !== 'undefined') {
    _required = labelRequired; // 优先使用labelRequired传值
  } else if (_readonly) {
    _required = false; // 只读时隐藏星号
  } else if (required) {
    _required = true; // 有传值时 展示
  } else if (rules?.length) {
    // rules里有required时 展示
    const ruleRequired = rules.filter(
      (fil: Record<string, any>) => fil.required,
    );
    if (ruleRequired.length) {
      _required = true;
    }
  }
  // dom
  let valueDom = null;
  if (!renderFormItem && _readonly) {
    valueDom = <span>{data?.[name] || '-'}</span>;
  } else if (renderFormItem) {
    valueDom = renderFormItem;
  } else if (valueType || valueEnum) {
    valueDom = (
      <Field
        mode={mode}
        valueType={valueType}
        valueEnum={valueEnum}
        fieldProps={fieldProps}
      />
    );
  } else {
    valueDom = (
      <Input
        placeholder={placeholder || `请输入${label}`}
        {...fieldProps}
        allowClear={
          typeof fieldProps?.allowClear !== 'undefined'
            ? fieldProps?.allowClear
            : true
        }
      />
    );
  }
  const _item: Record<string, any> = {
    required: _required,
    label,
    name,
    valueDom,
    rules,
    placeholder,
    ...props,
  };
  return _item;
};

import ProTable from '@ant-design/pro-table';
import { ConfigProvider, theme } from 'antd';
import { useContext, useEffect, useRef, useState } from 'react';
import { SwitchGroup } from '../components';
import type { TableProps } from '../types';
import { formatColumns } from '../utils';
import { tableStyle } from './index.style';

/**
 * 📦 内置组件 - 隐力科技
 * @name 更便捷的Table
 * @package @/aismile-atom
 * @author xuguanqun
 */
const Table: React.FC<TableProps> = ({
  toolbar,
  bordered = true,
  columns,
  onSizeChange,
  sticky = true,
  scroll,
  // highPerformance,
  serialNumber = true,
  className,
  rowKey = 'id',
  ...props
}) => {
  const {
    title: toolbarTitle,
    subTitle: toolbarTitleSubTitle,
    switchList: toolbarSwitchList,
    middleArea: toolbarMiddleArea,
    actions: toolbarActions = [],
    settings: toolbarSettings = true,
  } = toolbar || {};
  const showToolBar =
    toolbarSettings ||
    toolbarTitle ||
    toolbarTitleSubTitle ||
    toolbarSwitchList ||
    toolbarMiddleArea;
  /**
   * @name state
   */
  const ref: any = useRef();
  const [_columns, _setcolumns] = useState<any[]>([]);
  const [_tableSize, _settableSize] = useState<string>('middle'); // 表格尺寸
  /**
   * @name CONTEXT
   */
  const ANTD_CONTEXT = useContext(ConfigProvider.ConfigContext);
  const prefixCls = ANTD_CONTEXT.getPrefixCls();
  /**
   * @name props
   */
  const tableDom: any = document.querySelector(`.${prefixCls}-table`);
  const toolbarDom: any = document.querySelector(
    `.${prefixCls}-pro-table-list-toolbar`,
  );
  const tableOffsetTop = tableDom?.offsetTop || 0; // 表格距离顶部高度
  const toolbarHeight = toolbarDom?.clientHeight || 0; // 工具栏高度
  const theadHeight = { large: 55, middle: 47, small: 39 }[_tableSize]; // 表头高度
  const paginationHeight = { large: 64, middle: 56, small: 56 }[_tableSize]; // 分页器高度
  // 滚动条
  let _scroll: any = {};
  if (scroll) {
    _scroll = { ...scroll };
    // 吸底
    if (scroll?.y === 'sticky-bottom') {
      if (
        typeof tableOffsetTop !== 'undefined' &&
        typeof toolbarHeight !== 'undefined' &&
        typeof theadHeight !== 'undefined' &&
        typeof paginationHeight !== 'undefined'
      ) {
        _scroll.y = `calc(100vh - ${tableOffsetTop}px - ${toolbarHeight}px - ${theadHeight}px - ${paginationHeight}px)`;
      }
    }
  }
  if (!scroll?.x) {
    const x = _columns.map((v) => v.width || 0).reduce((a, b) => a + b, 0);
    _scroll.x = x;
  }
  /**
   * @name theme
   */
  const {
    token: { colorPrimary, colorPrimaryBorderHover },
  } = theme.useToken();
  /**
   * @name useEffect
   */
  useEffect(() => {
    const columns2comp = formatColumns(columns, serialNumber);
    _setcolumns(columns2comp);
  }, [columns]);
  return (
    <>
      <ProTable
        className={[
          tableStyle({ prefixCls, colorPrimary, colorPrimaryBorderHover }),
          className,
        ]
          .filter(Boolean)
          .join(' ')}
        actionRef={ref}
        toolbar={undefined}
        headerTitle={
          showToolBar && (
            <>
              <div className={`${prefixCls}-custom-title`}>
                <span>{toolbarTitle}</span>
                <span className={`${prefixCls}-custom-subtitle`}>
                  {toolbarTitleSubTitle}
                </span>
              </div>
              <SwitchGroup options={toolbarSwitchList} />
              {toolbarMiddleArea && (
                <div className={`${prefixCls}-middle-area`}>
                  {toolbarMiddleArea}
                </div>
              )}
            </>
          )
        }
        toolBarRender={() => toolbarActions}
        options={showToolBar ? { fullScreen: true, reload: false } : false}
        search={false}
        bordered={bordered}
        columns={_columns}
        scroll={_scroll}
        onSizeChange={(size: any) => {
          if (onSizeChange) {
            onSizeChange(size);
          }
          _settableSize(size);
        }}
        // components={highPerformance ? { body: VirtualList } : undefined}
        revalidateOnFocus={false}
        sticky={sticky}
        rowKey={rowKey}
        {...props}
      />
    </>
  );
};
export default Table;

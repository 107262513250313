import { Button, ConfigProvider, Drawer, Form } from 'antd';
import { useContext, useEffect } from 'react';
import { BaseForm } from '../components';
import { DrawerFormProps } from '../types';
import { drawerFormStyle } from './index.style';

/**
 * 📦 内置组件 - 隐力科技
 * @name 抽屉表单
 * @package @/aismile-atom
 * @author xuguanqun
 */
const DrawerForm: React.FC<DrawerFormProps> = ({
  data,
  formItemList,
  formLoading,
  onOk,
  formProps,
  readonly: formReadOnly,
  children,
  open,
  visible,
  ...drawerProps
}) => {
  const [formInstance] = Form.useForm();
  const _onOk = async (e: any) => {
    const formData = await formInstance.validateFields();
    if (onOk) {
      onOk(formData, formInstance, e);
    }
  };
  useEffect(() => {
    if (open || visible) {
      if (data) {
        formInstance.setFieldsValue(data);
      }
    } else {
      formInstance.resetFields();
    }
  }, [open, visible, data]);
  /**
   * @name CONTEXT
   */
  const ANTD_CONTEXT = useContext(ConfigProvider.ConfigContext);
  const prefixCls = ANTD_CONTEXT.getPrefixCls();
  const baseFormProps = {
    form: formInstance,
    data,
    formItemList,
    formLoading,
    onOk,
    formProps,
    formReadOnly,
  };
  return (
    <>
      <Drawer
        className={drawerFormStyle(prefixCls)}
        open={open || visible}
        {...drawerProps}
        extra={
          <Button type="primary" onClick={_onOk}>
            确定
          </Button>
        }
      >
        <BaseForm {...baseFormProps} />
      </Drawer>
      {children}
    </>
  );
};
export default DrawerForm;

import { CLIENT_TYPE } from 'typings';

// 平台端口
export const CLIENT: CLIENT_TYPE = 'SYS';
// 七牛云上传地址
export const QINIU_UPLOAD = 'https://upload.qiniup.com';
// file
export const FILE_HOST = 'https://file.aismile.cn';
// iconfont
export const ICONFONT = '';

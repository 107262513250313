import { ConfigProvider as AtomConfigProvider } from '@/aismile-atom';
import '@aismile/diagnosis-veneer/dist/index.less';
import '@aismile/image-viewer/dist/index.less';
import { request, useRequest } from '@umijs/max';
import 'antd-v3.26.20/dist/antd.css';

AtomConfigProvider.config({
  req: {
    request,
    useRequest,
  },
});

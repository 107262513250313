import { css as emotionCss } from '@emotion/css';

export const opsBtnStyle = (type: string) => {
  return emotionCss`
  ${type === 'link' ? 'padding: 0 !important;' : ''}
  .ops-btn-number {
    margin-left: 5px;
    font-weight: 500;
  }  `;
};
export const opsBtnLinkStyle = () => {
  return emotionCss`
    ${opsBtnStyle('link')}
    &::before {
      background: none;
    }
    `;
};
export const dropdownMenuStyle = (
  prefixCls: string,
  token: Record<string, any> = {},
) => {
  return emotionCss`
  .${prefixCls}-dropdown-menu-item:not(.${prefixCls}-dropdown-menu-item-disabled, .${prefixCls}-dropdown-menu-item-danger) {
    color: ${token?.colorPrimary};
  }
    `;
};

import { css as emotionCss } from '@emotion/css';

export const modalFormStyle = (prefixCls: string) => {
  return emotionCss`
  > .${prefixCls}-modal-content {
    > .${prefixCls}-modal-header {
      user-select: none;
    }
    > .${prefixCls}-modal-body {
      padding: 15px;
    }
  }
  
  `;
};

import { CustomPageHeaderProps } from '@/types/custom-page-header';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { history } from '@umijs/max';
import { Affix, Space } from 'antd';
import CustomAvatar from '../custom-avatar';
import styles from './index.less';

/**
 * @name 自定义页头组件
 */
const CustomPageHeader: React.FC<CustomPageHeaderProps> = ({
  photo,
  subTitle,
  extra,
  affix = true,
}) => {
  const DOM = (
    <div className={styles['custom-page-header']}>
      <div className={styles['custom-page-header-left']}>
        <ArrowLeftOutlined
          className={styles.back}
          onClick={() => {
            history.back();
          }}
          style={{ fontSize: 18 }}
        />
        {photo && <CustomAvatar hash={photo} />}
        <div>{subTitle}</div>
      </div>
      <Space>{extra?.map((item: any) => item)}</Space>
    </div>
  );
  const affixParams = typeof affix !== 'boolean' ? affix : { offsetTop: 0 };
  return affix ? <Affix {...affixParams}>{DOM}</Affix> : DOM;
};

export default CustomPageHeader;

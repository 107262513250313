import defaultAvatar from '@/assets/images/default-avatar.png';
import { CustomAvatarProps } from '@/types';
import { useModel } from '@umijs/max';
import { Avatar } from 'antd';

/**
 * @name 自定义头像组件
 */
const CustomAvatar: React.FC<CustomAvatarProps> = ({
  className,
  src,
  hash,
  children,
  alt,
  size,
  shape = 'circle',
  style,
}) => {
  const {
    initialState: { systemConfig },
  }: any = useModel('@@initialState');
  const qiniuDomain = systemConfig?.qiniu_domain?.value;
  const imgSrc = src ? src : hash ? `${qiniuDomain}/${hash}` : defaultAvatar;
  return imgSrc ? (
    <Avatar
      className={className}
      src={imgSrc}
      alt={alt}
      size={size}
      shape={shape}
      style={{
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        border: '1px solid rgba(0, 0, 0, 0.1)',
        ...style,
      }}
    >
      {children}
    </Avatar>
  ) : null;
};

export default CustomAvatar;

import { ConfigProvider, Form, Modal } from 'antd';
import { useContext, useEffect } from 'react';
import { BaseForm } from '../components';
import { ModalFormProps } from '../types';
import { modalFormStyle } from './index.style';

/**
 * 📦 内置组件 - 隐力科技
 * @name 弹窗表单
 * @package @/aismile-atom
 * @author xuguanqun
 */
const ModalForm: React.FC<ModalFormProps> = ({
  form,
  data,
  formItemList,
  formLoading,
  onOk,
  formProps,
  readonly,
  children,
  visible,
  open,
  ...modalProps
}) => {
  const [_formInstance] = Form.useForm();
  const formInstance = form || _formInstance;
  const _onOk = async (e: any) => {
    const formData = await formInstance.validateFields();
    if (onOk) {
      onOk(formData, formInstance, e);
    }
  };
  useEffect(() => {
    if (open || visible) {
      if (data) {
        formInstance.setFieldsValue(data);
      }
    } else {
      formInstance.resetFields();
    }
  }, [open, visible, data]);
  /**
   * @name CONTEXT
   */
  const ANTD_CONTEXT = useContext(ConfigProvider.ConfigContext);
  const prefixCls = ANTD_CONTEXT.getPrefixCls();
  const baseFormProps = {
    form: formInstance,
    data,
    formItemList,
    formLoading,
    onOk,
    formProps,
    readonly,
  };
  return (
    <>
      <Modal
        destroyOnClose
        className={modalFormStyle(prefixCls)}
        open={open || visible}
        {...modalProps}
        onOk={_onOk}
      >
        <BaseForm {...baseFormProps} />
      </Modal>
      {children}
    </>
  );
};
export default ModalForm;

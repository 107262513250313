import { ConfigProvider, Radio } from 'antd';
import { useContext } from 'react';
import { SwitchGroupProps } from '../../types';

/**
 * 📦 内置组件 - 隐力科技
 * @name 搜索栏状态切换组
 * @package @/aismile-atom
 * @author xuguanqun
 */
const SwitchGroup: React.FC<SwitchGroupProps> = ({ options }) => {
  /**
   * @name CONTEXT
   */
  const ANTD_CONTEXT = useContext(ConfigProvider.ConfigContext);
  const prefixCls = ANTD_CONTEXT.getPrefixCls();
  return (
    <div className={`${prefixCls}-switch-group`}>
      {options?.map((item, _index: number) => {
        const {
          title: _title,
          groupList = [],
          value,
          onChange: groupOnChange,
        } = item;
        const index = _index + 1;
        return (
          <div className={`${prefixCls}-switch-item`} key={index}>
            <span className={`${prefixCls}-switch-title`}>{_title}</span>
            <Radio.Group
              value={value}
              onChange={(e) => {
                const val = e.target.value;
                if (groupOnChange) {
                  groupOnChange(val);
                }
              }}
              options={groupList.map((l: any) => {
                return {
                  key: l.id,
                  label: l.label,
                  value: l.id,
                };
              })}
              optionType="button"
              buttonStyle="solid"
            />
          </div>
        );
      })}
    </div>
  );
};

export default SwitchGroup;
